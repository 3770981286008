.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus{
	background-color: #6d579d;
}

img{
	max-width:100%;
}

.loading-div{
	position:fixed;
	display:flex;
	align-items: center;
	align-content: center;
	top:0;
	left:0;
	background-color:rgba(0,0,0,0.5);
	color:#fff;
	font-size:34px;
	text-align:center;
	height:100%;
	width:100%;
	z-index:99;
	font-family: 'Raleway';
}

.loading-div > div{
	margin:0 auto;
}
.flot-x-axis .tickLabel{
	max-width:none !important;
	transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-moz-transform: rotate(45deg);
	/* Firefox */
	-webkit-transform: rotate(45deg);
	/* Safari and Chrome */
	-o-transform: rotate(45deg);
}

.form-inline .btn{
	margin-bottom:0 !important;
}

.fd-tile.clean.tile-gold .details{
	background-color: #FEC35D;
}

#breakdown_date_range{
	margin-left:10px;
}

.clearfix{
	clear:both;
}

@media all and (max-width:768px){
	.form-inline input, .form-inline button{
		margin-bottom:10px;
	}
}