/** initial setup **/
.nano {
  position : relative;
  width    : 100%;
  height   : 100%;
  overflow : hidden;
}
.nano .content {
  position      : absolute;
  overflow      : scroll;
  overflow-x    : hidden;
  top           : 0;
  right         : 0;
  bottom        : 0;
  left          : 0;
}
.nano .content:focus {
  outline: thin dotted;
}
.nano .content::-webkit-scrollbar {
  visibility: hidden;
}
.has-scrollbar .content::-webkit-scrollbar {
  visibility: visible;
}
.nano > .pane {
  background : rgba(0,0,0,.10);
  position   : absolute;
  width      : 7px;
  right      : 0;
  top        : 0;
  bottom     : 0;
  visibility : hidden\9; /* Target only IE7 and IE8 with this hack */
  opacity    : .01; 
  -webkit-transition    : .2s;
  -moz-transition       : .2s;
  -o-transition         : .2s;
  transition            : .2s;
}
.nano > .pane > .slider {
  background: #444;
  background: rgba(0,0,0,.25);
  position              : relative;
  margin                : 0 1px;
}
.nano:hover > .pane, .pane.active, .pane.flashed {
  visibility : visible\9; /* Target only IE7 and IE8 with this hack */
  opacity    : 0.99;
}
